<template>
  <div id="infoZipOutLining" class="uk-flex-top" data-uk-modal @hidden="onHidden">
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" data-uk-close />

      <div class="uk-modal-header uk-padding uk-padding-remove-bottom uk-margin-bottom" style="border: none">
        <h3 class="uk-text-bold">What is zip-out lining?</h3>
      </div>

      <div class="uk-modal-body uk-padding uk-padding-remove-top" data-uk-overflow-auto>
        <p>
          If you expect to add more patches or embroidery to the body of the jacket at a later stage, we suggest
          choosing a zip-out lining. This will allow you to customize your jacket whenever you’re ready to do so.
        </p>

        <!--        <img data-src="/resources/assets/frontend/img/brand/placeholder.svg" alt="Zip-out lining" data-uk-img class="vb-rounded" />-->
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

export default {
  name: 'InfoZipOutliningModal',
  methods: {
    onHidden() {
      UIkit.offcanvas('#offCanvasCart')?.show()
    },
  },
}
</script>
