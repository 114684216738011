<template>
  <div id="infoExtraPocket" class="uk-flex-top" data-uk-modal @hidden="onHidden">
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default" type="button" data-uk-close />

      <div class="uk-modal-header uk-padding uk-padding-remove-bottom uk-margin-bottom" style="border: none">
        <h3 class="uk-text-bold">What is additional pocket?</h3>
      </div>

      <div class="uk-modal-body uk-padding uk-padding-remove-top" data-uk-overflow-auto>
        <p>
          Our jackets come with one pocket as a standard (left side). For just
          <strong>{{ price }}</strong>
          we can add another pocket on the right side.
        </p>

        <img
          :data-src="getImgPath('/img/uploads/pockets-preview.jpg')"
          alt="Inside pockets preview"
          data-uk-img
          class="vb-rounded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

import { getImgPath } from '@/shared/js/helpers'

export default {
  name: 'InfoExtraPocketModal',
  props: {
    price: {
      type: String,
      default: '$0.00',
    },
  },
  methods: {
    getImgPath,
    onHidden() {
      UIkit.offcanvas('#offCanvasCart')?.show()
    },
  },
}
</script>
